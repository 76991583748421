<template>
    <div>
        <Alert />
        <div
            class="flex items-center justify-center"
            :style="`background-image: url(${require('@/assets/images/login-bg.jpg')})`"
            style="background-size: cover; height: 100vh"
        >
            <div class="w-50-l w-90">
                <!-- Simplebks logo -->
                <div class="tc pb4">
                    <img :src="require('@/assets/images/simplebks-logo.png')" style="width: 180px; height: auto" alt="simplebks-logo" />
                </div>
                <!-- Simplebks logo -->

                <!-- login/Input -->
                <div style="background-color: white">
                    <form class="center pa5-l pa3" @submit.prevent="onSubmit">
                        <h2 class="tc">Welcome</h2>
                        <div class="flex" style="gap: 1rem">
                            <div class="flex flex-column pb3 w-100">
                                <label class="pb2">Create New Password</label>
                                <input
                                    class="form-sign"
                                    type="password"
                                    name="password"
                                    placeholder="*************"
                                    id="password"
                                    required
                                    v-model="state.password"
                                />
                            </div>

                            <div class="flex flex-column pb3 w-100">
                                <label class="pb2">Confirm Password</label>
                                <input
                                    class="form-sign"
                                    type="password"
                                    name="password2"
                                    placeholder="*************"
                                    id="password2"
                                    required
                                    v-model="state.password2"
                                />
                            </div>
                        </div>
                        <!--                    <div class="flex justify-between">-->
                        <!--                        <label class="container">-->
                        <!--                            <input type="checkbox" checked="checked" />Remember me-->
                        <!--                            <span class="checkmark"></span>-->
                        <!--                        </label>-->
                        <!--                        <div style="font-size: 14px"><a href="">Forget Password?</a></div>-->
                        <!--                    </div>-->
                        <button class="mt3 w-100 submit-btn" type="submit" :disabled="state.disableButton">
                            {{ state.disableButton ? 'Processing, please wait...' : 'Continue' }}
                        </button>
                        <!--                    <div class="tc mt3">-->
                        <!--                        Don't have account? <a href="Signup-page.html"><b>Register</b></a>-->
                        <!--                    </div>-->
                    </form>
                </div>
                <!-- login/Input -->
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Alert from '@/components/Alert'

export default {
    name: 'InviteSignup',
    components: { Alert },

    setup() {
        const router = useRouter()
        const store = useStore()
        const { query } = router?.currentRoute?.value

        const initialState = () => ({
            disableButton: false,
            password: '',
            password2: '',
        })
        const state = reactive(initialState())

        const onSubmit = async () => {
            state.disableButton = true

            if (state.password !== state.password2) {
                await store.dispatch('Alert/setAlert', { message: 'Passwords do not match', status: false })
                state.disableButton = false
                return
            }

            const payload = {}

            const formData = {
                password: state.password,
                password2: state.password2,
            }

            payload.formData = formData
            payload.query = query

            store
                .dispatch('Admin/inviteSignup', payload)
                .then(() => {
                   state.disableButton = false
                })
                .catch(() => {
                    state.disableButton = false
                })
        }

        onMounted(() => store.dispatch('Admin/verifyAdminInvite', query))

        return { state, onSubmit }
    },
}
</script>

<style scoped></style>
